// @flow

import React from 'react'
import type { Node } from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import { Heading1 } from '../components'

function NotFoundPage(): Node {
  return (
    <Layout>
      <div className="bg-purple text-white">
        <div className="pt-8 pb-2 md:py-32 px-8">
          <div className="w-full max-w-xl mx-auto">
            <div>
              <Heading1 className="mb-2 text-5xl" isTitle>
                Hey! This is a 404.
              </Heading1>
              <p className="text-purple-lightest leading-normal max-w-md">
                It seems you’ve either manually typed in or discovered a page
                that <span className="italic">doesn’t</span> exist. If you
                believe this is an error or you clicked a link that brought you
                here, we’d love for you to get in touch with us on our{' '}
                <Link to="/contact-us" className="text-purple-lightest">
                  contact page.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
